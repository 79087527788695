var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-card',{attrs:{"dark":_vm.$dark.get(),"elevation":"1"}},[_c('v-card-title',{staticClass:"card_title"},[_c('p',[_vm._v("Модерация товаров")]),_c('div',{staticClass:"card_filters"},[_c('p',[_vm._v("Фильтры")]),_c('v-checkbox',{attrs:{"label":"Без картинок"},on:{"change":function($event){return _vm.setAll()}},model:{value:(_vm.body.missingImage),callback:function ($$v) {_vm.$set(_vm.body, "missingImage", $$v)},expression:"body.missingImage"}}),_c('v-checkbox',{attrs:{"label":"Без описания"},on:{"change":function($event){return _vm.setAll()}},model:{value:(_vm.body.missingDescription),callback:function ($$v) {_vm.$set(_vm.body, "missingDescription", $$v)},expression:"body.missingDescription"}}),_c('v-select',{staticClass:"card_select",attrs:{"items":_vm.statuses,"item-text":"name","item-value":"value"},on:{"change":function($event){return _vm.setAll()}},model:{value:(_vm.body.status),callback:function ($$v) {_vm.$set(_vm.body, "status", $$v)},expression:"body.status"}}),_c('v-select',{staticClass:"card_select",attrs:{"items":_vm.PROVIDERS.allProviders,"label":"Поставщики","item-text":"name","item-value":"id","clearable":""},on:{"change":function($event){return _vm.setAll()}},model:{value:(_vm.body.providerId),callback:function ($$v) {_vm.$set(_vm.body, "providerId", $$v)},expression:"body.providerId"}})],1)]),_c('v-card-text',[(_vm.loading)?_c('loader'):_c('v-simple-table',{attrs:{"fixed-header":""}},[_c('thead',[_c('tr',[_c('th',[_c('v-checkbox',{on:{"change":function($event){_vm.checks.forEach(function (el) {
                    el.state = _vm.all;
                  })}},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1),_vm._l((_vm.items.head),function(item){return _c('th',{key:item.id},[_c('span',[_vm._v(_vm._s(item.name))])])})],2)]),_c('tbody',{staticStyle:{"max-height":"92vh","min-height":"64vh"}},_vm._l((_vm.items.products),function(item,index){return _c('tr',{key:item.providerProduct.id + '/' + index},[_c('td',{staticClass:"cell"},[_c('v-checkbox',{on:{"change":function($event){_vm.all = false}},model:{value:(_vm.checks[index].state),callback:function ($$v) {_vm.$set(_vm.checks[index], "state", $$v)},expression:"checks[index].state"}})],1),_c('td',{staticClass:"cell"},[_vm._v(_vm._s(item.providerProduct.id))]),_c('td',{staticClass:"cell"},[_vm._v(_vm._s(_vm.providers[item.providerId]))]),_c('td',{staticClass:"cell cell_img"},[(
                  item.providerProduct.images
                    ? item.providerProduct.images.length === 0
                    : true
                )?_c('p',[_vm._v(" ? ")]):_c('v-img',{attrs:{"width":"50px","height":"50px","src":item.providerProduct.images[0].fileUrl}})],1),_c('td',{staticClass:"cell"},[_vm._v(_vm._s(item.providerProduct.name))]),_c('td',{staticClass:"cell"},[_vm._v(_vm._s(item.providerProduct.description))]),_c('td',{staticClass:"cell"},[_vm._v(" "+_vm._s(item.comment ? item.comment : "Не указан")+" ")]),_c('td',{staticClass:"cell"},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.moderate([item], 'APPROVED')}}},[_c('v-icon',[_vm._v("mdi-thumb-up")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.showCommentDialog = true;
                  _vm.currentProduct = item;}}},[_c('v-icon',[_vm._v("mdi-thumb-down")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/product-difference/' + item.moderationId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-ab-testing")])],1)]}}],null,true)},[_c('span',[_vm._v("Посмотреть изменения")])])],1)])}),0)]),(_vm.totalPages > 1)?_c('v-pagination',{attrs:{"value":_vm.body.page + 1,"dark":_vm.$dark.get(),"total-visible":7,"length":_vm.totalPages},on:{"input":function($event){return _vm.setAll($event - 1)}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"activator":"parent","width":"70%"},model:{value:(_vm.showCommentDialog),callback:function ($$v) {_vm.showCommentDialog=$$v},expression:"showCommentDialog"}},[_c('v-card',{staticClass:"modal",attrs:{"dark":_vm.$dark.get()}},[_c('v-card-title',{staticClass:"flex justify-center"},[_vm._v(" Укажите причину отказа в публикации ")]),_c('v-card-text',[_c('v-form',{ref:"rejectForm"},[_c('v-textarea',{attrs:{"rules":[_vm.$validate.required],"label":"Причина отказа","auto-grow":"","clearable":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_vm._l((_vm.TAGS.moderateTags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"ma-2",on:{"click":function($event){_vm.comment = tag.name}}},[_vm._v(" "+_vm._s(tag.name)+" ")])})],2),_c('v-card-actions',{staticClass:"modal__actions"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.checkComment()}}},[_vm._v("Подвердить")]),_c('v-btn',{on:{"click":function($event){_vm.showCommentDialog = false}}},[_vm._v("Закрыть")])],1)],1)],1),_c('bottom-bar',{attrs:{"active":_vm.active,"disapprove":true,"disable":_vm.states.length > 1},on:{"show":function($event){(_vm.showCommentDialog = true), (_vm.massReject = true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }